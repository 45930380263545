import React from 'react';
import { Icon } from '@iconify/react'
function Pagination({ totalEntries, entriesPerPage, currentPage, onPageChange,setmodule }) {
    const totalPages = Math.ceil(totalEntries / entriesPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };
 

    return (
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
            <span>Showing {currentPage * entriesPerPage - entriesPerPage + 1} to {Math.min(currentPage * entriesPerPage, totalEntries)} of {totalEntries} entries</span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link bg-neutral-100 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                        onClick={() => handlePageChange(currentPage - 1)}

                    >
                        <Icon icon="ep:d-arrow-left" ></Icon>
                    </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index} className="page-item">
                        <button className={`page-link ${currentPage === index + 1 ? 'bg-primary-600 text-white' : 'bg-neutral-100 text-secondary-light'} fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px`} onClick={() => handlePageChange(index + 1)}>
                            {index + 1}
                        </button>
                    </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} >
                    <button className="page-link bg-neutral-100 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                        onClick={() => handlePageChange(currentPage + 1)}>
                        <Icon icon="ep:d-arrow-right"></Icon>
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default Pagination;
