// import logo from './logo.svg';
import './App.css';
import SideMenu from './common/SideMenu';
import { RouterProvider } from 'react-router-dom';
import AppRoutes from './routes';
import ErrorBoundary from './common/ErrorBoundary';

function App() {
  const router = AppRoutes()
  console.log("======routers===",router)
  return (   
      <RouterProvider router={router} />
    
  );
}

export default App;
