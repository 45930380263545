import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import smtpSchema from '../../schema/smtp'
import { Form } from 'react-bootstrap'
import ProfileApis from '../../queries/profile'
import toast from 'react-hot-toast'

function SMTPDetails({ SMTPDetails,getSmtpDetails }) {
  const { register, formState: { errors }, reset, handleSubmit } = useForm({
    defaultValues: {
      from: SMTPDetails?.data?.from,
      host: SMTPDetails?.data?.host,
      username: SMTPDetails?.data?.username,
      password: SMTPDetails?.data?.password,
      tls: SMTPDetails?.data?.tls,
      port: SMTPDetails?.data?.port
    },
    resolver: yupResolver(smtpSchema)
  })
  const updateMailDetails = ProfileApis.UpdateSmtpDetails()
  console.log("======errors===", errors)
  const submitHandler = async (data) => {
    console.log("==data==", data)
    if(data.tls===true){
      data.tls=1
    }else{
      data.tls=0
    }
    const response = await updateMailDetails.mutateAsync(data)
    if (response.status) {
      toast.success('SMTP details updated')
      await getSmtpDetails()
    }else{
      toast.error('Something went wrong')
    }
  }
  return (
    <div className="tab-pane fade" id="pills-smtp-content" role="tabpanel" aria-labelledby="pills-smtp-tab" tabIndex="0">
      <h6 className="text-md text-primary-light mb-16">SMTPDetails</h6>
      <form action="#" onSubmit={handleSubmit(submitHandler)}>
        <div className="row">
          <div className="col-sm-6">
            <div className="mb-20">
              <label htmlFor="firstName" className="form-label fw-semibold text-primary-light text-sm mb-8">
                From  <span className="text-danger-600">*</span>
              </label>
              <input
                type="text"
                className={`form-control radius-8 ${errors?.from ? 'is-invalid' : ''}`}
                {...register('from')}
                id="firstName"
                placeholder="Enter from mail"
              />
              <div className="invalid-feedback">
                {errors?.from?.message}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-20">
              <label htmlFor="email" className="form-label fw-semibold text-primary-light text-sm mb-8">
                Host <span className="text-danger-600">*</span>
              </label>
              <input
                type="text"
                className={`form-control radius-8 ${errors?.host ? 'is-invalid' : ''}`}
                {...register('host')}
                id="email"
              />
              <div className="invalid-feedback">
                {errors?.host?.message}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-20">
              <label htmlFor="lastName" className="form-label fw-semibold text-primary-light text-sm mb-8" >
                Username
              </label>
              <input
                type="text"
                className={`form-control radius-8 ${errors?.username ? 'is-invalid' : ''}`}
                {...register('username')}
                id="lastName"
                placeholder="Enter username"
              />
              <div className="invalid-feedback">
                {errors?.username?.message}
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="mb-20">
              <label htmlFor="mobile" className="form-label fw-semibold text-primary-light text-sm mb-8">
                Password
              </label>
              <input
                type="text"
                className={`form-control radius-8 ${errors?.password ? 'is-invalid' : ''}`}
                {...register('password')}
                id="mobile"
                placeholder="Enter password"
              />
              <div className="invalid-feedback">
                {errors?.password?.message}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-20">
              <label htmlFor="mobile" className="form-label fw-semibold text-primary-light text-sm mb-8">
                Port
              </label>
              <input
                type="text"
                className={`form-control radius-8 ${errors?.port ? 'is-invalid' : ''}`}
                {...register('port')}
                id="mobile"
                placeholder="Enter port"
              />
              <div className="invalid-feedback">
                {errors?.port?.message}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="mb-20 tls-switch">
              <label htmlFor="custom-switch" className="form-label fw-semibold text-primary-light text-sm mb-8">
                TLS
              </label>
              <Form.Check
                type="switch"
                id="custom-switch"
                {...register('tls')}
              />
            </div>
            <span>{errors?.tls?.message}</span>

          </div>


        </div>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <button
            type="button"
            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
            onClick={() => reset()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default SMTPDetails;