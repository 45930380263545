import React from 'react'
import ApexCharts from 'react-apexcharts';
import { Icon } from '@iconify/react'



function FinancialRecordTiles() {
    const createWidgetChartOptions = (chartColor) => {
        const currentYear = new Date().getFullYear();

        return {
            series: [
                {
                    name: 'series1',
                    data: [35, 45, 38, 41, 36, 43, 37, 55, 40],
                },
            ],
            chart: {
                type: 'area',
                width: 100,
                height: 42,
                sparkline: {
                    enabled: true,
                },
                toolbar: {
                    show: false,
                },
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 2,
                colors: [chartColor],
                lineCap: 'round',
            },
            grid: {
                show: true,
                borderColor: 'transparent',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
                row: {
                    colors: undefined,
                    opacity: 0.5,
                },
                column: {
                    colors: undefined,
                    opacity: 0.5,
                },
                padding: {
                    top: -3,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
            fill: {
                type: 'gradient',
                colors: [chartColor],
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.5,
                    gradientToColors: [`${chartColor}00`],
                    inverseColors: false,
                    opacityFrom: 0.75,
                    opacityTo: 0.3,
                    stops: [0, 100],
                },
            },
            markers: {
                colors: [chartColor],
                strokeWidth: 2,
                size: 0,
                hover: {
                    size: 8,
                },
            },
            xaxis: {
                labels: {
                    show: false,
                },
                categories: [
                    `Jan ${currentYear}`,
                    `Feb ${currentYear}`,
                    `Mar ${currentYear}`,
                    `Apr ${currentYear}`,
                    `May ${currentYear}`,
                    `Jun ${currentYear}`,
                    `Jul ${currentYear}`,
                    `Aug ${currentYear}`,
                    `Sep ${currentYear}`,
                    `Oct ${currentYear}`,
                    `Nov ${currentYear}`,
                    `Dec ${currentYear}`,
                ],
                tooltip: {
                    enabled: false,
                },
            },
            yaxis: {
                labels: {
                    show: false,
                },
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm',
                },
            },
        };
    };
    return (
        <div className="admin_content_row">
            <h6>Financial Record</h6>
            <div className="users_list_section">
                <div className="row gy-4 mb-3">
                    <div className="col-xxl-3 col-sm-6">
                        <div className="card p-3 shadow-none radius-8 border h-100">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                                    <div className="d-flex align-items-center gap-2">
                                        <span className="mb-0 w-48-px h-48-px bg-primary-600 flex-shrink-0 text-white d-flex justify-content-center align-items-center rounded-circle h6 mb-0">
                                            <Icon icon="mingcute:user-follow-fill" />
                                        </span>
                                        <div>
                                            <span className="mb-2 fw-medium text-secondary-light text-sm">Total Earnings</span>
                                            <h6 className="fw-semibold">15,000</h6>
                                        </div>
                                    </div>
                                    <ApexCharts
                                        options={createWidgetChartOptions('#487fff')}
                                        series={[{ data: [35, 45, 38, 41, 36, 43, 37, 55, 40] }]}
                                        type="area"
                                        height={42}
                                        width={100}
                                        className='remove-tooltip-title rounded-tooltip-value'

                                    />
                                </div>
                                <p className="text-sm mb-0">
                                    Increase by <span className="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">+200</span> this week
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-sm-6">
                        <div className="card p-3 shadow-none radius-8 border h-100">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                                    <div className="d-flex align-items-center gap-2">
                                        <span class="mb-0 w-48-px h-48-px bg-success-main flex-shrink-0 text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                            <Icon icon="mingcute:user-follow-fill" />
                                        </span>
                                        <div>
                                            <span className="mb-2 fw-medium text-secondary-light text-sm">Total Payout</span>
                                            <h6 className="fw-semibold">8,000</h6>
                                        </div>
                                    </div>
                                    <ApexCharts
                                        options={createWidgetChartOptions('#45b369')}
                                        series={[{ data: [35, 45, 38, 41, 36, 43, 37, 55, 40] }]}
                                        type="area"
                                        height={42}
                                        width={100}
                                        className='remove-tooltip-title rounded-tooltip-value'

                                    />
                                </div>
                                <p className="text-sm mb-0">
                                    Increase by <span className="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">+200</span> this week
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="col-xxl-3 col-sm-6">
                        <div className="card p-3 shadow-none radius-8 border h-100">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                                    <div className="d-flex align-items-center gap-2">
                                        <span class="mb-0 w-48-px h-48-px bg-yellow text-white flex-shrink-0 d-flex justify-content-center align-items-center rounded-circle h6">
                                            <Icon icon="mdi:account-pending-outline" />
                                        </span>
                                        <div>
                                            <span className="mb-2 fw-medium text-secondary-light text-sm">Payout Pending</span>
                                            <h6 className="fw-semibold">$5,00,000</h6>
                                        </div>
                                    </div>
                                    <ApexCharts
                                        options={createWidgetChartOptions('#f4941e')}
                                        series={[{ data: [35, 45, 38, 41, 36, 43, 37, 55, 40] }]}
                                        type="area"
                                        height={42}
                                        width={100}
                                        className='remove-tooltip-title rounded-tooltip-value'

                                    />
                                </div>
                                <p className="text-sm mb-0">
                                <p class="text-sm mb-0">Increase by  <span class="bg-danger-focus px-1 rounded-2 fw-medium text-danger-main text-sm">-$10k</span> this week</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-sm-6">
                        <div className="card p-3 shadow-none radius-8 border h-100">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                                    <div className="d-flex align-items-center gap-2">
                                        <span class="mb-0 w-48-px h-48-px bg-purple text-white flex-shrink-0 d-flex justify-content-center align-items-center rounded-circle h6">
                                            <Icon icon="iconoir:cash" />
                                        </span>
                                        <div>
                                            <span className="mb-2 fw-medium text-secondary-light text-sm">Total Balance</span>
                                            <h6 className="fw-semibold">25%</h6>
                                        </div>
                                    </div>
                                    <ApexCharts
                                        options={createWidgetChartOptions('#8252e9')}
                                        series={[{ data: [35, 45, 38, 41, 36, 43, 37, 55, 40] }]}
                                        type="area"
                                        height={42}
                                        width={100}
                                        className='remove-tooltip-title rounded-tooltip-value'

                                    />
                                </div>
                                <p class="text-sm mb-0">Increase by  <span class="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">+5%</span> this week</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancialRecordTiles