import React from 'react'
import Chart from "react-apexcharts";

export default function StudentStatistics({ dountChartData, setperiod, period }) {
  
    const options = {
        series: [dountChartData.previous.percentage, dountChartData.current.percentage],
        colors: ['#18145c', '#32b1d9'],
        labels: dountChartData?.labels,
        legend: {
            show: false
        },
        chart: {
            type: 'donut',
            height: 230,
            sparkline: {
                enabled: true
            },
            margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        },
        stroke: {
            width: 0,
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
    };

    const selectOptions = [
        {
            title: 'Yearly',
            value: 'year'
        },
        {
            title: 'Monthly',
            value: 'month'
        },
        {
            title: 'Weekly',
            value: 'week'
        },
        {
            title: 'Day',
            value: 'day'
        },
    ]
    const selectPeriodHandler = (e) => {
        const { value } = e.target
        setperiod(value)
    }
    return (
        <div className="col-xxl-4 col-lg-6">
            <div className="card h-100 radius-8 border-0">
                <div className="card-body p-24">
                    <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                        <h6 className="mb-2 fw-bold text-lg">Students Statistics</h6>
                        <div>
                            <select className="form-select form-select-sm w-auto bg-base border text-secondary-light" value={period} onChange={selectPeriodHandler}>
                                {
                                    selectOptions.map((item) => (
                                        <option value={item.value}>{item.title}</option>
                                    ))
                                }

                            </select>
                        </div>
                    </div>

                    <div className="position-relative">
                        <span className="w-80-px h-80-px bg-base shadow text-primary-light fw-semibold text-xl d-flex justify-content-center align-items-center rounded-circle position-absolute end-0 top-0 z-1">
                            +{dountChartData?.previous?.percentage}%
                        </span>
                        <div id="statisticsDonutChart" className="mt-30 flex-grow-1 apexcharts-tooltip-z-none title-style circle-none">
                            <Chart options={options} series={options.series} type="donut" />
                        </div>
                        <span className="w-80-px h-80-px bg-base shadow text-primary-light fw-semibold text-xl d-flex justify-content-center align-items-center rounded-circle position-absolute start-0 bottom-0 z-1">
                            +{dountChartData?.current?.percentage}%
                        </span>
                    </div>

                    <ul className="d-flex flex-wrap align-items-center justify-content-between mt-3 gap-3">
                        <li className="d-flex align-items-center gap-2">
                            <span className="w-12-px h-12-px radius-2 bg-primary-600"></span>
                            <span className="text-secondary-light text-sm fw-normal">
                                {dountChartData?.labels[1]}: <span className="text-primary-light fw-bold">{dountChartData?.current?.quantity}</span>
                            </span>
                        </li>
                        <li className="d-flex align-items-center gap-2">
                            <span className="w-12-px h-12-px radius-2 bg-darkblue"></span>
                            <span className="text-secondary-light text-sm fw-normal">
                                {dountChartData?.labels[0]}: <span className="text-primary-light fw-bold">{dountChartData?.previous?.quantity}</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}
