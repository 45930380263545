import * as yup from "yup"

const Companyschema = yup
    .object({
        name:yup.string().required(),
        email: yup.string().required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid mail format"),
        facebook: yup.string().optional(),
        twitter:yup.string().optional(),
        linkedin:yup.string().optional()
    })
    .required()
export default Companyschema