import { useMutation, useQuery } from '@tanstack/react-query'
import { getAllTutors, AddTutor, changeTutorStatus, getRegCourse, editTutorDetails, getTutorDetails } from '../service/tutor/tutorService';

const TutorApis = {
    GetallTutors: (page, length, status, username) => {
        const response = useQuery({
            queryKey: ['get-all-tutors', page, length, status, username],
            queryFn: () => getAllTutors(page, length, status, username)
        })
        return response;
    },
    ChangeTutorStatus: () => {
        const response = useMutation({
            mutationFn: (data) => changeTutorStatus(data)
        })
        return response
    },
    AddTutor: () => {
        const response = useMutation({
            mutationFn: (data) => AddTutor(data)
        })
        return response
    },
    GetRegCourses: () => {
        const response = useQuery({
            queryKey: ['get-reg-courses'],
            queryFn: () =>getRegCourse()
        })
        return response;
    },
    EditTutorDetails:()=>{
        const response = useMutation({
            mutationFn: (data) => editTutorDetails(data)
        })
        return response
    },
    GetTutorDetails:(userId)=>{
        const response = useQuery({
            queryKey: ['get-tutor-details',userId],
            queryFn: () =>getTutorDetails(userId),
            enabled:userId?true:false
        })
        return response;
    }
}
export default TutorApis;