import React from 'react'

function Addmodule({ setshowAddModuleModal }) {
    return (
        <div className="col-md-12 mt-3" id="module_add-before_item">
            <button
                className="btn btn-primary py-4 d-flex gap-2"
                // data-bs-toggle="modal"
                // data-bs-target="#staticBackdrop-add-module"
                onClick={() => setshowAddModuleModal(true)}
            >
                Add Module
            </button>
        </div>
    )
}

export default Addmodule