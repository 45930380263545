import { useAuth } from './context/AuthContext';
import MainLayout from './layout/MainLayout';
import MainDashboard from './components/dashboard/MainDashboard';
import Tutors from './view/tutor/Tutors';
import Student from './view/student/Student';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import AuthLayout from './layout/AuthLayout';
import Course from './view/course/Course';
import Module from './view/course/Module';
import FinancialRecord from './view/financial-record/FinancialRecord';
import ErrorBoundary from './common/ErrorBoundary';
import Login from './view/auth/Login';
import { useSelector } from 'react-redux'
import Profile from './view/profile/Profile';
import TutorDashboard from './view/Tutor/Dashboard';
import SalesReport from './view/sales-report/SalesReport';
import Invoice from './view/sales-report/Invoice';
import ForgotPassword from './view/auth/ForgotPassword';
import ResetPassword from './view/auth/ResetPassword';

const AppRoutes = () => {
    const { isAuthenticated, userType } = useAuth();

    const sharedRoutes = [
        { path: 'profile', element: <Profile /> },
        { path: 'students', element: <Student /> },
        { path: 'course', element: <Course /> },
        { path: 'module/:id', element: <Module /> },
        { path: 'sales-report', element: <SalesReport /> },
        { path: 'invoice/:invoice', element: <Invoice /> },
    ];

    const routesConfig = {
        admin: [
            { path: 'dashboard', element: <MainDashboard /> },
            { path: 'tutors', element: <Tutors /> },
            { path: 'financial-record', element: <FinancialRecord /> },
        ],
        tutor: [
            { path: 'dashboard', element: <TutorDashboard /> },
        ],
    };


    const publicRoutes = [
        {
            path: '/',
            element: <AuthLayout />,
            errorElement: <ErrorBoundary />,

            children: [
                { path: '/', element: <Navigate to="/login" replace />  },
                { path: '/login', element: <Login />  },
                { path: '/forgot-password', element: <ForgotPassword />},
                { path: '/reset-password/:token', element: <ResetPassword />},
                { path: '*', element: <Navigate to="/login" replace /> },
            ],
        }
    ];

    const privateRoutes = [
        {
            path: '/',
            element: <MainLayout />,
            errorElement: <ErrorBoundary />,
            children: [
                { path: '/', element: <Navigate to="/dashboard" replace />, index: true },
                ...routesConfig[userType] || [],
                ...sharedRoutes,
                { path: '*', element: <Navigate to="/dashboard" replace /> },
            ],
        },
    ];




    return createBrowserRouter(isAuthenticated ? privateRoutes : publicRoutes);
};


export default AppRoutes;
