import React from 'react'

function NotificationSettings() {
    return (
        <div className="tab-pane fade" id="pills-notification" role="tabpanel" aria-labelledby="pills-notification-tab" tabIndex="0">
            <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                <label htmlFor="companzNew" className="position-absolute w-100 h-100 start-0 top-0"></label>
                <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">Company News</span>
                    <input className="form-check-input" type="checkbox" role="switch" id="companzNew" />
                </div>
            </div>

            <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                <label htmlFor="pushNotifcation" className="position-absolute w-100 h-100 start-0 top-0"></label>
                <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">Push Notification</span>
                    <input className="form-check-input" type="checkbox" role="switch" id="pushNotifcation" defaultChecked />
                </div>
            </div>

            <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                <label htmlFor="weeklyLetters" className="position-absolute w-100 h-100 start-0 top-0"></label>
                <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">Weekly News Letters</span>
                    <input className="form-check-input" type="checkbox" role="switch" id="weeklyLetters" defaultChecked />
                </div>
            </div>

            <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                <label htmlFor="meetUp" className="position-absolute w-100 h-100 start-0 top-0"></label>
                <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">Meetups Near You</span>
                    <input className="form-check-input" type="checkbox" role="switch" id="meetUp" />
                </div>
            </div>

            <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                <label htmlFor="orderNotification" className="position-absolute w-100 h-100 start-0 top-0"></label>
                <div className="d-flex align-items-center gap-3 justify-content-between">
                    <span className="form-check-label line-height-1 fw-medium text-secondary-light">Orders Notifications</span>
                    <input className="form-check-input" type="checkbox" role="switch" id="orderNotification" defaultChecked />
                </div>
            </div>
        </div>

    )
}

export default NotificationSettings