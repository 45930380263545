import { useMutation, useQuery } from '@tanstack/react-query'
import { forgotPassword, login, logout, resetPassword } from '../service/auth/authService';
const AuthApis = {
    LoginUser: () => {
        const response = useMutation({
            mutationFn: (data) => login(data),

        })
        return response
    },
    Logout: () => {
        const response = useMutation({
            mutationFn: () => logout(),

        })
        return response
    },
    ForgotPassword:()=>{
        const response = useMutation({
            mutationFn: (data) => forgotPassword(data),

        })
        return response
    },
    ResetPassword:()=>{
        const response = useMutation({
            mutationFn: (data) => resetPassword(data),

        })
        return response
    }
}
export default AuthApis;