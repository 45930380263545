import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ModuleSchema from '../../schema/module';
import { yupResolver } from '@hookform/resolvers/yup';
import CourseApis from '../../queries/course';
import toast from 'react-hot-toast';

function AddmoduleModal({ show, handleClose, courseId, getAllmodules }) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({ resolver: yupResolver(ModuleSchema) });

    const addNewModule = CourseApis.AddNewModule();

    const addModule = async (data) => {
        console.log("Submitting data...");  // Check if this logs

        try {

            data.courseId = courseId;
            const response = await addNewModule.mutateAsync(data);

            if (response.status) {
                toast.success('Module added successfully');
                handleClose();
                await getAllmodules()
                reset();
            } else {
                toast.error('Failed to add module');
            }
        } catch (error) {
            toast.error('Error adding module');
        }
    };

    const modalClose = () => {
        handleClose()
        reset()
    }
    return (
        <Modal
            show={show}
            onHide={modalClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title><h6>Add Module</h6></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form /*className="add_course_popup"*/ >
                    {console.log("Submitting form")}  {/* This should log when form submits */}
                    <div className="user-details">
                        <Form.Group className="input-box" controlId="formModuleName">
                            <Form.Label className="details">Module Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Module Name"
                                {...register('name')}
                                className={`${errors?.name ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors?.name?.message}</div>
                        </Form.Group>

                        <Form.Group className="input-box" controlId="formDuration">
                            <Form.Label className="details">Description</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                placeholder="Description"
                                {...register('description')}
                                className={`${errors?.description ? 'is-invalid' : ''}`}
                            />
                            <div className="invalid-feedback">{errors?.description?.message}</div>
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={modalClose}>
                    Close
                </Button>
                <Button variant="primary" id="add_module_btn" type="submit"
                    // disabled={addNewModule.isPending}
                    onClick={handleSubmit(addModule)}>
                    Add Module
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddmoduleModal;
