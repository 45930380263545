import React from 'react'
import SideMenu from '../common/SideMenu'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { Outlet } from 'react-router-dom'
import MainDashboard from '../components/dashboard/MainDashboard'
import $ from 'jquery';
import { useEffect } from 'react'
import Tutors from '../view/tutor/Tutors'
import Student from '../view/student/Student'
import Aos from 'aos'
import { useLocation } from 'react-router-dom'
import DashboardApis from '../queries/dashboard'
export default function MainLayout() {
  useEffect(() => {
    Aos.init()
  }, []);

  useEffect(() => {
    // Sidebar submenu collapsible logic
    $(".sidebar-menu .dropdown").on("click", function () {
      const item = $(this);
      item.siblings(".dropdown").children(".sidebar-submenu").slideUp();
      item.siblings(".dropdown").removeClass("dropdown-open open");
      item.children(".sidebar-submenu").slideToggle();
      item.toggleClass("dropdown-open");
    });

    // Sidebar toggle logic
    $(".sidebar-toggle").on("click", function () {
      $(this).toggleClass("active");
      $(".sidebar").toggleClass("active");
      $(".dashboard-main").toggleClass("active");
    });

    $(".sidebar-mobile-toggle").on("click", function () {
      $(".sidebar").addClass("sidebar-open");
      $("body").addClass("overlay-active");
    });

    $(".sidebar-close-btn").on("click", function () {
      $(".sidebar").removeClass("sidebar-open");
      $("body").removeClass("overlay-active");
    });

    // Set current page as active
    (function setActivePage() {
      let nk = window.location;
      let o = $("ul#sidebar-menu a")
        .filter(function () {
          return this.href === nk;
        })
        .addClass("active-page")
        .parent()
        .addClass("active-page");

      while (o.is("li")) {
        o = o.parent().addClass("show").parent().addClass("open");
      }
    })();

    // Theme toggle logic
    const button = document.querySelector("[data-theme-toggle]");
    const localStorageTheme = localStorage.getItem("theme");

    const calculateSettingAsThemeString = ({ localStorageTheme }) => {
      if (localStorageTheme !== null) {
        return localStorageTheme;
      }
      return "light";
    };

    const updateButton = ({ buttonEl, isDark }) => {
      const newCta = isDark ? "dark" : "light";
      buttonEl.setAttribute("aria-label", newCta);
      buttonEl.innerText = newCta;
    };

    const updateThemeOnHtmlEl = ({ theme }) => {
      document.querySelector("html").setAttribute("data-theme", theme);
    };

    let currentThemeSetting = calculateSettingAsThemeString({ localStorageTheme });

    updateButton({ buttonEl: button, isDark: currentThemeSetting === "dark" });
    updateThemeOnHtmlEl({ theme: currentThemeSetting });

    button.addEventListener("click", () => {
      const newTheme = currentThemeSetting === "dark" ? "light" : "dark";
      localStorage.setItem("theme", newTheme);
      updateButton({ buttonEl: button, isDark: newTheme === "dark" });
      updateThemeOnHtmlEl({ theme: newTheme });
      currentThemeSetting = newTheme;
    });

    // Table Header Checkbox checked all logic
    $('#selectAll').on('change', function () {
      $('.form-check .form-check-input').prop('checked', $(this).prop('checked'));
    });

    // Remove Table Row logic
    $('.remove-btn').on('click', function () {
      $(this).closest('tr').remove();

      // Check if the table has no rows left
      if ($('.table tbody tr').length === 0) {
        $('.table').addClass('bg-danger');
        // Show notification
        $('.no-items-found').show();
      }
    });

    // Cleanup event listeners on component unmount
    return () => {
      $(".sidebar-menu .dropdown").off();
      $(".sidebar-toggle").off();
      $(".sidebar-mobile-toggle").off();
      $(".sidebar-close-btn").off();
      $('#selectAll').off();
      $('.remove-btn').off();
    };
  }, []); // Empty dependency array ensures this runs only on mount
  const { data: adminDetails, refetch: getAdminDetails } = DashboardApis.GetAdmindetails()
  return (
    <>
      <SideMenu />
      <main className="dashboard-main" >
        <Header adminDetails={adminDetails} />
        <Outlet context={{ getAdminDetails }} />
        <Footer />
      </main>
    </>
  )
}
