import React from 'react'
import Login from '../view/auth/Login'
import {Outlet} from 'react-router-dom'

function AuthLayout() {
    return (
        <Outlet />
    )
}

export default AuthLayout