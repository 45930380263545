import { useMutation, useQuery } from '@tanstack/react-query'
import { getAdminDetails, getNotifications, getRecentPurchase, getRevenuereport, getStudentStatics, getTiles, getTopStudents, getTopTutors, getTransactions, readNotification } from '../service/dashboard/dashboardService';
import { useAuth } from '../context/AuthContext';
const DashboardApis = {
    GetTiles: () => {
        const response = useQuery({
            queryKey: ['get-tiles'],
            queryFn: () => getTiles()
        })
        return response;
    },
    StudentStatics: (period) => {
        const response = useQuery({
            queryKey: ['get-student-statics', period],
            queryFn: () => getStudentStatics(period)
        })
        return response;
    },
    RecentPurchase: (start, length = 5, name) => {
        const { userType } = useAuth()
        const response = useQuery({
            queryKey: ['get-recent-purchase', start, length, name],
            queryFn: () => getRecentPurchase(start, length, name),
        })
        return response
    },
    Toptutors: (limit = 5) => {
        const response = useQuery({
            queryKey: ['get-top-tutors', limit],
            queryFn: () => getTopTutors(limit)
        })
        return response
    },
    TopStudents: (limit = 5) => {
        const response = useQuery({
            queryKey: ['get-top-students', limit],
            queryFn: () => getTopStudents(limit)
        })
        return response
    },
    Transactions: (type) => {
        const response = useQuery({
            queryKey: ['get-transactions', type],
            queryFn: () => getTransactions(type)
        })
        return response
    },

    RevenueReport: (period) => {
        const response = useQuery({
            queryKey: ['get-revenue-report', period],
            queryFn: () => getRevenuereport(period)
        })
        return response
    },
    GetNotification: (limit) => {
        const response = useQuery({
            queryKey: ['get-revenue-report', limit],
            queryFn: () => getNotifications(limit)
        })
        return response
    },
    ReadNotification: () => {
        const response = useMutation({
            mutationFn: (data) => readNotification(data)
        })
        return response
    },
    GetAdmindetails: () => {
        const response = useQuery({
            queryKey: ['get-admin-details'],
            queryFn: () => getAdminDetails()
        })
        return response
    }


}
export default DashboardApis;