import api from "../../api/axios.instance";

export const getAllStudents = async (start, length, status, name) => {
    try {
        const response = await api.get('common/all-Students', { params: { start, length, status, name } })
        return response
    } catch (error) {
        return error
    }
}

export const changeStudentstatus = async (data) => {
    try {
        const response = await api.post('admin/update-user-status', data)
        return response
    } catch (error) {
        return error
    }
}
export const AddStudent = async (data) => {
    try {
        const response = await api.post('register-user', data)
        return response
    } catch (error) {
        return error
    }
}

export const editStudentDetails = async (data) => {
    try {
        const response = await api.post('admin/update-user-profile', data)
        return response
    } catch (error) {
        return error
    }
}
export const getStudentDetails = async (userId) => {
    try {
        const response = await api.get('admin/user-details', { params: { userId } })
        return response
    } catch (error) {
        return error
    }
}

