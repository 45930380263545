import React from 'react'
import { Icon } from '@iconify/react'
import noDataimg from '../../assets/images/No data-cuate.png'
import Pagination from '../../common/Pagination'
import TutorApis from '../../queries/tutor'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import StudentApis from '../../queries/student'
import TableSkeleton from '../../utils/Skeletons/TableSkeleton'
function StudentListTable(
  {
    setModalShow,
    studentTabledata,
    page,
    length,
    setpage,
    status,
    username,
    setstatus,
    setusername,
    seteditModal,
    getAllstudents,
    seteditUserId,
    IsapiFetching,
    userType
  }
) {

  const updateUserStatus = StudentApis.ChangeStudentStatus()

  const changeStudentstatus = async (action, id) => {
    const body = {
      userId: id,
      action: action
    }
    const response = await updateUserStatus.mutateAsync(body)
    if (response.status) {
      await getAllstudents()
    }
  }
  const openEditmodal = (userId) => {
    seteditUserId(userId)
    seteditModal(true)
  }
  return (
    <div className="card h-100">
      <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
        <div className="d-flex align-items-center flex-wrap gap-3">
          <form className="navbar-search">
            <input type="text" className="bg-base h-40-px w-auto" name="search" placeholder="Enter username" value={username}
              onChangeCapture={(e) => setusername(e.target.value)} />
            <Icon icon="ion:search-outline" className="icon"></Icon>
          </form>
          <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px"
            value={status}
            onClick={(e) => setstatus(e.target.value)}>
            {
              [{ title: 'Status', value: '' },
              { title: 'Active', value: 1 },
              { title: 'Inactive', value: 0 }].map((item) => (
                <option value={item.value}>{item.title}</option>
              ))
            }
          </select>
        </div>
        {
          userType === "admin" &&
          <a href="#" className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2" onClick={() => setModalShow(true)}>
            <Icon icon="ic:baseline-plus" className="icon text-xl line-height-1"></Icon>
            Add New Student
          </a>
        }

      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-style mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Fullname</th>
                <th>Course Count</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            {
              IsapiFetching ?
                <TableSkeleton rowCount={6} colCount={6} />
                :
                <tbody>
                  {
                    studentTabledata?.tabledata?.length > 0 ?
                      studentTabledata?.tabledata?.map((item, index) => {
                        const tableIndex = (page - 1) * length + index + 1
                        return (
                          <tr>
                            <td>{tableIndex}</td>
                            <td>{item.username}</td>
                            <td>{item.fullName}</td>
                            <td>{item.courseCount}</td>
                            <td>{item.joiningDate}</td>
                            <td>
                              <div className="d-flex align-items-center gap-10">
                                {
                                  userType === "admin" &&
                                  <button type="button" className="card-edit-button text-success-600"
                                    onClick={() => openEditmodal(item.id)}>
                                    <Icon icon="lucide:edit" className="icon text-lg line-height-1"></Icon>
                                  </button>
                                }

                                {
                                  item.active ?
                                    <OverlayTrigger
                                      placement='right'
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>
                                          <span>Block user</span>
                                        </Tooltip>
                                      }
                                    >
                                      <button type="button" className="card-delete-button text-danger-600"
                                        onClick={() => changeStudentstatus('block', item.id)}>
                                        <Icon icon="fluent:delete-24-regular" className="icon text-lg line-height-1"></Icon>
                                      </button>
                                    </OverlayTrigger>

                                    :
                                    <OverlayTrigger
                                      placement='right'
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>
                                          <span>Click to unblock</span>
                                        </Tooltip>
                                      }
                                    >
                                      <button type="button" className="card-delete-button text-danger-600"
                                        onClick={() => changeStudentstatus('unblock', item.id)}>
                                        <Icon icon="mdi:person-block" className="icon text-lg line-height-1"></Icon>

                                      </button>
                                    </OverlayTrigger>

                                }

                              </div>
                            </td>
                          </tr>
                        )
                      })
                      :
                      <tr className='text-center'>
                        <td colSpan={5}>
                          <img src={noDataimg} className='w-50' />
                        </td>
                      </tr>
                  }

                </tbody>
            }

          </table>
        </div>
        <Pagination
          totalEntries={studentTabledata?.totalCount}
          entriesPerPage={length}
          currentPage={page}
          onPageChange={setpage}
        />

      </div>
    </div>

  )
}

export default StudentListTable