import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Vimeo from '@u-wave/react-vimeo';

function VideoPreview({ show, handleClose, videoUrl }) {
    const [videoLoading, setvideoLoading] = useState(true)
    const videoLoaded = () => {
        console.log('Video has been loaded');
        setvideoLoading(false)
    };
    console.log("==videoLoading====", videoLoading)
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <iframe
                    width="100%"
                    height="703"
                    src="https://www.youtube.com/embed/fvRVEmuAPGo"
                    title="New UX/UI Design Trends by Apple, Netflix &amp; More! + Figma Tutorial"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe> */}

                {videoLoading &&
                    <span>loading.....</span>
                }

                <Vimeo
                    video={videoUrl}
                    responsive
                    loop={false}
                    onLoaded={videoLoaded}
                />



            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default VideoPreview