import { useMutation, useQuery } from '@tanstack/react-query'
import { login, logout } from '../service/auth/authService';
import { getInvoiceDetails, getSalesReportTiles } from '../service/sales-report/salesReportService';

const SalesReportApis = {
    GetReportTiles: () => {
        const response = useQuery({
            queryKey: ["get-sales-report-tiles"],
            queryFn: () => getSalesReportTiles()
        })
        return response
    },
    GetInvoiceDetails: (invoice) => {
        const response = useQuery({
            queryKey: ["get-invoice-details", invoice],
            queryFn: () => getInvoiceDetails(invoice),
            enabled: invoice ? true : false
        })
        return response
    }
}
export default SalesReportApis;