import React from 'react'
import UserInfo from '../../components/profile/UserInfo'
import ProfileSettings from '../../components/profile/ProfileSettings'
import ProfileApis from '../../queries/profile'

function Profile() {
    const { data: profileData, refetch: getProfiledata } = ProfileApis.GetUserdetails()
    const { data: Companydetails, refetch: getCompanyDetails } = ProfileApis.GetCompanyDetails()
    const { data: smtpDetails, refetch: getSmtpDetails } = ProfileApis.GetSmtpDetails()
    console.log("====profileData====", profileData)
    return (
        <div className="dashboard-main-body">
            <div className="row gy-4">
                <UserInfo profileData={profileData} />

                <ProfileSettings
                    profileData={profileData}
                    getProfiledata={getProfiledata}
                    Companydetails={Companydetails}
                    getCompanyDetails={getCompanyDetails}
                    smtpDetails={smtpDetails}
                    getSmtpDetails={getSmtpDetails}
                />

            </div>

        </div>

    )
}

export default Profile