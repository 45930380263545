import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Companyschema from '../../schema/company'
import { Icon } from '@iconify/react';
import ProfileApis from '../../queries/profile';
import toast from 'react-hot-toast'

function CompanyDetails({ Companydetails, getCompanyDetails }) {
    const { register, formState: { errors }, reset, handleSubmit } = useForm({
        defaultValues: {
            name: Companydetails?.data?.name,
            email: Companydetails?.data?.email,
            facebook: Companydetails?.data?.facebook,
            twitter: Companydetails?.data?.twitter,
            linkedin: Companydetails?.data?.linkedin
        },
        resolver: yupResolver(Companyschema)
    })
    const updateCompanyDetails = ProfileApis.UpdateCompanydetails()
    const [imgPreview, setimgPreview] = useState('')
    const [file, setfile] = useState(null)
    
    const submitHandler = async (data) => {
        const formData = new FormData()
        formData.append('file', file)
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value)
        })
        const response = await updateCompanyDetails.mutateAsync(formData)
        if (response.status) {
            await getCompanyDetails()
            toast.success('Company details updated')
        } else {
            toast.error('Company details updation failed')
        }
    }
    
    useEffect(() => {
        setimgPreview(Companydetails?.data?.logo)
    }, [Companydetails])
    
    const fileChangeHandler = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setfile(selectedFile);
            setimgPreview(URL.createObjectURL(selectedFile));
        }
    };

    return (
        <div className="tab-pane fade" id="pills-company-details" role="tabpanel" aria-labelledby="pills-company-details-tab" tabIndex="0">
            <h6 className="text-md text-primary-light mb-16">Company Logo</h6>

            {/* Upload Image Start */}
            <div className="mb-24 mt-16">
                <div className="avatar-upload">
                    <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                        <input type="file" id="logoUpload" accept=".png, .jpg, .jpeg" hidden onChange={fileChangeHandler} />
                        <label
                            htmlFor="logoUpload"
                            className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle"
                        >
                            <Icon icon="solar:camera-outline" className="icon" />
                        </label>
                    </div>
                    <div className="avatar-preview">
                        <div id="imagePreview"> <img src={imgPreview ?? '/image/user.png'} /></div>
                    </div>
                </div>
            </div>
            {/* Upload Image End */}
            <form action="#" onSubmit={handleSubmit(submitHandler)}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="firstName" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Company Name <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control radius-8 ${errors?.name ? 'is-invalid' : ''}`}
                                {...register('name')}
                                id="firstName"
                                placeholder="Enter Company Name"
                            />
                            <div className="invalid-feedback">
                                {errors?.name?.message}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="email" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Email <span className="text-danger-600">*</span>
                            </label>
                            <input
                                type="email"
                                className={`form-control radius-8 ${errors?.email ? 'is-invalid' : ''}`}
                                {...register('email')}
                                id="email"
                                placeholder="Enter email address"
                            />
                            <div className="invalid-feedback">
                                {errors?.email?.message}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="lastName" className="form-label fw-semibold text-primary-light text-sm mb-8" >
                                Facebook
                            </label>
                            <input
                                type="text"
                                className={`form-control radius-8 ${errors?.facebook ? 'is-invalid' : ''}`}
                                {...register('facebook')}
                                id="lastName"
                                placeholder="Enter facebook link"
                            />
                            <div className="invalid-feedback">
                                {errors?.facebook?.message}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="mobile" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                LinkedIn
                            </label>
                            <input
                                type="text"
                                className={`form-control radius-8 ${errors?.linkedin ? 'is-invalid' : ''}`}
                                {...register('linkedin')}
                                id="mobile"
                                placeholder="Enter LinkedIn URL"
                            />
                            <div className="invalid-feedback">
                                {errors?.linkedin?.message}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-20">
                            <label htmlFor="mobile" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Twitter
                            </label>
                            <input
                                type="text"
                                className={`form-control radius-8 ${errors?.twitter ? 'is-invalid' : ''}`}
                                {...register('twitter')}
                                id="mobile"
                                placeholder="Enter twitter link"
                            />
                            <div className="invalid-feedback">
                                {errors?.twitter?.message}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3">
                    <button
                        type="button"
                        className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                        onClick={() => reset()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CompanyDetails
