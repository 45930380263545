import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import TaskSchema from '../../schema/task';
import CourseApis from '../../queries/course';
import toast from 'react-hot-toast'
// import { TimePicker } from 'antd';
import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';

const AddSubjectModal = ({ show, handleClose, getAllmodules, courseId, moduleId }) => {
  const { register, trigger, setError, formState: { errors }, handleSubmit, reset, control, watch } = useForm({
    resolver: yupResolver(TaskSchema),
  });
  const addNewTask = CourseApis.AddNewTask()

  const addTask = async (data) => {
    const isValid = await trigger();
    if (isValid) {
      data.courseId = courseId
      data.moduleId = moduleId
      const time = dayjs(data?.videoLength)
      const hour = time.hour()
      const minute = time.minute()
      const second = time.second()
      // const [hours, minutes, seconds] = data.videoLength.split(':').map(Number);
      const totalSeconds = (hour * 3600) + (minute * 60) + second;
      data.duration = totalSeconds
      const response = await addNewTask.mutateAsync(data)
      if (response.status) {
        await getAllmodules()
        toast.success('Task added')
        handleClose()
        reset({
          title: '',
          videoLength: null,
          url: ''
        });
      } else {
        if (response?.error?.field) {
          setError(response?.error?.field, { message: response?.error?.message })
        }
        toast.error('Task added failed')
      }
    }
  }

  const formInputs = watch()


  const modalClose = () => {
    handleClose()
    reset()
  }
  return (
    <Modal show={show} onHide={modalClose} centered size="lg" backdrop="static" enforceFocus={false} >
      <Modal.Header closeButton>
        <Modal.Title className="fw-semibold"><h6>Add subject</h6></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="add_course_popup">
          <div className="content">
            <div className="user-details">
              <Form.Group className="input-box w-100" controlId="formSubjectName">
                <Form.Label className="details">Subject Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Subject Name"
                  {...register('title')}
                  className={` ${errors.title ? 'is-invalid' : ''}`}
                />
                <div className="invalid-feedback">{errors?.title?.message}</div>
              </Form.Group>


              {/* <TimePicker
                placement='topRight'
                onChange={onChange}
                defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                defaultValue={dayjs('00:00:00', 'HH:mm:ss')}
                format="HH:mm:ss"
                onClick={(e) => e.stopPropagation()}  // Prevents the click event from propagating
                onFocus={(e) => e.stopPropagation()}
                getPopupContainer={(trigger) => trigger.parentNode}

              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Form.Group className="input-box w-100 duration-input" controlId="formTime">
                  <Form.Label className="details">Duration</Form.Label>
                  <Controller
                    name="videoLength"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TimePicker
                        {...field}
                        views={['hours', 'minutes', 'seconds']}
                        format='HH:mm:ss'
                        ampm={false}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={!!fieldState.error}
                            helperText={fieldState?.error?.message}

                          />
                        )}
                      />
                    )}
                  />
                  <span style={{ fontSize: '.875em', color: '#ef4a00' }}>{errors?.videoLength?.message}</span>
                </Form.Group>

              </LocalizationProvider>



              {/* <Form.Group className="input-box w-100" controlId="formDuration">
                <Form.Label className="details">Duration</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="HH:MM:SS"
                  {...register('videoLength')}
                  className={` ${errors.videoLength ? 'is-invalid' : ''}`}
                />
                <div className="invalid-feedback">{errors?.videoLength?.message}</div>
              </Form.Group> */}

              <Form.Group className="input-box w-100" controlId="formURL">

                <Form.Label className="details">URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="http://"
                  {...register('url')}
                  className={` ${errors.url ? 'is-invalid' : ''}`}
                />
                <div className="invalid-feedback">{errors?.url?.message}</div>
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={modalClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit(addTask)} disabled={addNewTask.isPending}>
          <span className="d-flex align-items-center">
            <i className="iconify" data-icon="ooui:add"></i>Add
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSubjectModal;
