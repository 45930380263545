import React, { useState } from 'react'
import RevenueReport from '../../components/dashboard/RevenueReport'
import RecentPurchases from '../../components/tutor-dashboard/RecentPurchases'
import Transactions from '../../components/tutor-dashboard/Transactions'
import Courses from '../../components/tutor-dashboard/Courses'
import DashboardApis from '../../queries/dashboard'
import Students from '../../components/tutor-dashboard/Students'
import StudentStatistics from '../../components/tutor-dashboard/StudentStatistics'
import Tiles from '../../components/tutor-dashboard/Tiles'
import TutorDashboardApis from '../../queries/tutor.dashboard'
import CourseApis from '../../queries/course'

function Dashboard() {
    const [period, setperiod] = useState('month')
    const [transactionPeriod, setTransactionPeriod] = useState('current')
    const [revenuePeriod, setrevenuePeriod] = useState('year')
    const { data: tiles } = TutorDashboardApis.GetTiles()
    const { data: dountChartData } = TutorDashboardApis.GetStudentStatics(period)
    const { data: recentPurchaseData } = TutorDashboardApis.GetPurchaseList()
    const { data: topStudents } = TutorDashboardApis.GetStudentsList()
    const { data: transactionsData } = TutorDashboardApis.GetTransactions(transactionPeriod)
    const { data: CourseData } = CourseApis.GetHighRateCourses()
    return (
        <div class="dashboard-main-body" data-aos="fade-right">
            <div class="row gy-4">

                <div className="col-xxl-12">
                    <div className="card radius-8 border-0">
                        {/* <span></span> */}
                        <div className="row">
                           

                            <Tiles
                                tilesData={tiles?.data}
                            />
                             {
                                dountChartData?.data &&
                                <StudentStatistics
                                    dountChartData={dountChartData?.data}
                                    setperiod={setperiod}
                                    period={period}
                                />
                            }
                        </div>
                    </div>
                </div>


                <RecentPurchases recentPurchaseData={recentPurchaseData?.data} />
                {
                    transactionsData?.data && <Transactions
                        transactionsData={transactionsData?.data}
                        setTransactionPeriod={setTransactionPeriod}
                        transactionPeriod={transactionPeriod}
                    />
                }

                <Courses CourseData={CourseData?.data} />
                <Students topStudents={topStudents?.data} />
            </div>
        </div>
    )
}

export default Dashboard