import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import logo from '../../assets/images/logo.png'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import AuthApis from '../../queries/auth';
import Aos from 'aos';

function ResetPassword() {
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        formState: { errors },
    } = useForm()
    const navigate = useNavigate()
    useEffect(() => {
        Aos.init();
    }, []);
    const { token } = useParams()
    const [eyeOpen, seteyeOpen] = useState(false)
    const resetPassword = AuthApis.ResetPassword()


    const submitHandler = async (data) => {
        data.token = token
        const response = await resetPassword.mutateAsync(data)
        if (response.status) {
            toast.success('Password reset successfull')
            reset()
            navigate('/login')

        } else {
            toast.error('Password reset failed')
        }
    }
    return (
        <section className="login_box_sec" data-aos="fade-right">
            <div className="login_box">
                <div className="max-w-464-px mx-auto w-100">
                    <div>
                        <a href="index.html" className="mb-40 max-w-290-px">
                            <img src={logo} alt="logo" />
                        </a>
                        <h4 className="mb-12">Reset Password</h4>
                        <p className="mb-32 text-secondary-light text-lg">
                            please enter your new password
                        </p>
                    </div>
                    <form onSubmit={handleSubmit(submitHandler)}>

                        <div className={`position-relative mb-20`}>
                            <div className={`icon-field ${errors.password ? 'pb-2' : 'mb-16'}`}>
                                <span className="icon top-50 translate-middle-y">
                                    <Icon icon="solar:lock-password-outline"></Icon>
                                </span>
                                <input
                                    type={`${eyeOpen ? 'text' : 'password'}`}
                                    id="your-password"
                                    placeholder="Password"
                                    {...register("password", { required: 'Password is required', minLength: { value: 8, message: "Minimum 8 characters" } })}
                                    className={`form-control h-56-px bg-neutral-50 radius-12 ${errors.password ? 'form-error' : ''}`}
                                />

                            </div>
                            <div class="invalid-feedback-form ">
                                {errors?.password?.message}
                            </div>

                            {/* <span
                        className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
                        data-toggle="#your-password"
                    ></span> */}
                            {
                                eyeOpen ?
                                    <Icon icon="pajamas:eye" width="24" height="24"
                                        className='cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light'
                                        onClick={() => seteyeOpen(!eyeOpen)} />
                                    :
                                    <Icon icon="ph:eye-closed"
                                        width="24" height="24"
                                        className='cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light'
                                        onClick={() => seteyeOpen(!eyeOpen)}
                                    />
                            }
                        </div>



                        <button
                            type="submit"
                            className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword