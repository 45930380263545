import React from 'react'

function ListRankedTutors({ topTutorsData }) {
    
    return (
        <div className="admin_content_row">
            <h6>Top Tutors</h6>
            <div className="users_list_section">
                <div className="users_list_row">
                    {
                        topTutorsData?.tabledata?.map((item) => {
                            return (
                                <div className="users_list_box">
                                    <div className="users_list_box_img">
                                        <img src={item.image??'image/user.png'}/>
                                    </div>
                                    <div className="users_list_box_cnt">
                                        <h6>{item.fullName}</h6>
                                        <p>{item.course}</p>
                                        <h6>Course Count: {item.courseCount}</h6>
                                    </div>
                                </div>
                            )
                        })
                    }

                   
                </div>
            </div>
        </div>

    )
}

export default ListRankedTutors