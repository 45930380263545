import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StudentApis from '../../queries/student';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import StudentEditschema from '../../schema/student.edit';
import toast from 'react-hot-toast'

function EditStudentDetails({ show, handleClose, getAllstudents, editUserId }) {
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(StudentEditschema),
    })
    const { data: studentDetails } = StudentApis.GetStudentDetails(editUserId)
    console.log("==editUserId====",editUserId)
    const editStudent = StudentApis.EditStudentDetails()
    const submitHandler = async (data) => {
        const isValid = await trigger()
        if (isValid) {
            data.userId = editUserId
            console.log("==data====", data)
            const response = await editStudent.mutateAsync(data)
            if (response.status) {
                toast.success('student details updated')
                await getAllstudents()
                handleClose()
            } else {
                toast.error('student details updation failed')
            }
        }
    }

    useEffect(() => {
        if (studentDetails?.data) {
            Object.entries(studentDetails?.data).map(([key, value]) => {
                return setValue(key, value)
            })
        }

    }, [studentDetails])
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title><h6>Edit student details</h6></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-md text-primary-light mb-16">Profile Image</h6>
                {/* Upload Image Start */}
                {/* <div className="mb-24 mt-16">
      <div className="avatar-upload">
        <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
          <Form.Control type="file" id="imageUpload" accept=".png, .jpg, .jpeg" hidden />
          <label htmlFor="imageUpload" className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle">
            <Icon icon="solar:camera-outline"  className="icon" />
          </label>
        </div>
        <div className="avatar-preview">
          <div id="imagePreview"></div>
        </div>
      </div>
    </div> */}
                {/* Upload Image End */}

                <Form onSubmit={handleSubmit(submitHandler)}>
                    <Row>
                        <Col sm="6" className="mb-20">
                            <Form.Group controlId="name">
                                <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                                    First Name <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter firstname"
                                    className={`radius-8 ${errors.firstName ? 'is-invalid' : ''}`}
                                    {...register('firstName')} />
                                <div class="invalid-feedback">
                                    {errors?.firstName?.message}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col sm="6" className="mb-20">
                            <Form.Group controlId="name">
                                <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                                    Last Name
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter lastname"
                                    className={`radius-8 ${errors.lastName ? 'is-invalid' : ''}`}
                                    {...register('lastName')} />
                                <div class="invalid-feedback">
                                    {errors?.lastName?.message}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col sm="6" className="mb-20">
                            <Form.Group controlId="email">
                                <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                                    Email <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter email address"
                                    className={`radius-8 ${errors.email ? 'is-invalid' : ''}`}
                                    {...register('email')} />
                                <div class="invalid-feedback">
                                    {errors?.email?.message}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col sm="6" className="mb-20">
                            <Form.Group controlId="number">
                                <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                                    Phone
                                </Form.Label>
                                <Form.Control type="text" placeholder="Enter phone number"
                                    className={`radius-8 ${errors.mobile ? 'is-invalid' : ''}`} {...register('mobile')} />
                                <div class="invalid-feedback">
                                    {errors?.mobile?.message}
                                </div>
                            </Form.Group>
                        </Col>

                        <Col sm="6" className="mb-20">
                            <Form.Group controlId="email">
                                <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                                    Password <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control type="password" placeholder="*********"
                                    className={`radius-8 ${errors.password ? 'is-invalid' : ''}`}
                                    {...register('password')} />
                                <div class="invalid-feedback">
                                    {errors?.password?.message}
                                </div>
                            </Form.Group>
                        </Col>
                        {/* <Col sm="6" className="mb-20">
            <Form.Group controlId="desig">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Designation <span className="text-danger-600">*</span>
              </Form.Label>
              <Form.Control as="select" className={`radius-8 ${errors.designation ? 'is-invalid' : ''}`} {...register('designation')}>
                <option>Enter Designation Title</option>
                <option>Enter Designation Title One</option>
                <option>Enter Designation Title Two</option>
              </Form.Control>
              <div class="invalid-feedback">
                {errors?.designation?.message}
              </div>
            </Form.Group>
          </Col> */}
                        {/* <Col sm="6" className="mb-20">
            <Form.Group controlId="Language">
              <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                Language <span className="text-danger-600">*</span>
              </Form.Label>
              <Form.Control as="select" className={`radius-8 ${errors.language ? 'is-invalid' : ''}`} {...register('language')}>
                <option>English</option>
                <option>Bangla</option>
                <option>Hindi</option>
                <option>Arabic</option>
              </Form.Control>
              <div class="invalid-feedback">
                {errors?.language?.message}
              </div>
            </Form.Group>
          </Col> */}
                        <Col sm="12" className="mb-20">
                            <Form.Group controlId="desc">
                                <Form.Label className="fw-semibold text-primary-light text-sm mb-8">
                                    About
                                </Form.Label>
                                <Form.Control as="textarea" placeholder="Write description..." className={`radius-8 ${errors.about ? 'is-invalid' : ''}`} {...register('about')} />
                                <div class="invalid-feedback">
                                    {errors?.about?.message}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                        <Button variant="outline-danger" onClick={handleClose} className="text-md px-56 py-11 radius-8">
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" className="text-md px-56 py-12 radius-8">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EditStudentDetails