import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import logo from '../../assets/images/logo.png'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import AuthApis from '../../queries/auth';
import Aos from 'aos';


function ForgotPassword() {
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        formState: { errors },
    } = useForm()
    const navigate = useNavigate()
    useEffect(() => {
        Aos.init();
    }, []);
    const forgotPswd = AuthApis.ForgotPassword()
    const submitHanler = async (data) => {
        const response = await forgotPswd.mutateAsync(data)
        if (response.status) {
            reset()
            navigate('/login')
            toast.success('Check your email for further procedures')
        } else {
            if (response.code === 4021) {
                setError('email', { message: "Invalid email" })
            }else{
                toast.error('something went wrong')
            }
        }
    }
    return (
        <section className="login_box_sec" data-aos="fade-right">
            <div className="login_box">
                <div className="max-w-464-px mx-auto w-100">
                    <div>
                        <a href="index.html" className="mb-40 max-w-290-px">
                            <img src={logo} alt="logo" />
                        </a>
                        <h4 className="mb-12">Forgot Password</h4>
                        <p className="mb-32 text-secondary-light text-lg">
                            please enter your email
                        </p>
                    </div>
                    <form onSubmit={handleSubmit(submitHanler)}>
                        <div className={`icon-field ${errors.email ? 'pb-2' : 'mb-16'}`}>
                            <span className="icon top-50 translate-middle-y">
                                <Icon icon="mage:email"></Icon>
                            </span>
                            <input
                                type="text"
                                placeholder="Email"
                                className={`form-control h-56-px bg-neutral-50 radius-12 ${errors?.email ? 'form-error' : ''}`}
                                {...register("email", {
                                    required: "email is required", pattern: {
                                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                        message: 'Invalid mail format'
                                    }
                                })}
                            />

                        </div>
                        <div className="invalid-feedback-form mb-2">
                            {errors?.email?.message}
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ForgotPassword