import * as yup from "yup"

const smtpSchema = yup
    .object({
        from:yup.string().required(),
        host: yup.string().required(),
        username: yup.string().required(),
        password:yup.string().required(),
        tls:yup.boolean().required('TLS selection is required'),
        port:yup.string().required()
    })
    .required()
export default smtpSchema