import React from 'react';
import FinancialRecordTiles from '../../components/financial-record/FinancialRecordTiles';
import FinacialReportTable from '../../components/financial-record/FinacialReportTable';

function FinancialRecord() {
  return (
    <div className="dashboard-main-body">
      <FinancialRecordTiles/>
      <FinacialReportTable/>
    </div>
  )
}

export default FinancialRecord
