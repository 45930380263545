import { AddStudent, changeStudentstatus, editStudentDetails, getAllStudents, getStudentDetails } from "../service/student/studentService";
import { useMutation, useQuery } from '@tanstack/react-query'


const StudentApis = {
    GetallStudents: (page, length, status, username) => {
        const response = useQuery({
            queryKey: ['get-all-Students', page, length, status, username],
            queryFn: () => getAllStudents(page, length, status, username)
        })
        return response;
    },
    ChangeStudentStatus: () => {
        const response = useMutation({
            mutationFn: (data) => changeStudentstatus(data)
        })
        return response
    },
    AddStudent: () => {
        const response = useMutation({
            mutationFn: (data) => AddStudent(data)
        })
        return response
    },

    EditStudentDetails: () => {
        const response = useMutation({
            mutationFn: (data) => editStudentDetails(data)
        })
        return response
    },
    GetStudentDetails: (userId) => {
        const response = useQuery({
            queryKey: ['get-Student-details', userId],
            queryFn: () => getStudentDetails(userId),
            enabled: userId ? true : false
        })
        return response;
    }
}
export default StudentApis;