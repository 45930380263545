import api from "../../api/axios.instance"

export const getSalesReportTiles = async () => {
    try {
        const response = await api.get('common/sales-tiles')
        return response
    } catch (error) {
        return error
    }
}
export const getInvoiceDetails = async (invoice) => {
    try {
        const response = await api.get('common/purchase-invoice', { params: { invoice } })
        return response
    } catch (error) {
        return error
    }
}
