import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { Icon } from '@iconify/react'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CourseSchema from '../../schema/course';
import CourseApis from '../../queries/course';
import toast from 'react-hot-toast'
function AddCourseModal({ show, handleClose, categoies, levels,getAllcourse,getCourseTiles}) {
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CourseSchema),
    })
    const addCourse = CourseApis.AddCourse()
    const submitHandler = async (data) => {
        console.log("====data=====",data)
        const isValid = await trigger()
        const formData = new FormData()
        if (isValid) {
            formData.append('file', file)
            Object.entries(data).forEach(([key, value]) => {
                formData.append(key, value);
            });
            const response=await addCourse.mutateAsync(formData)
            if(response.status){
                reset()
                toast.success('course added successfully')
                setfile(null)
                setimgPreview('')
                handleClose()
                await getAllcourse()
                await getCourseTiles()
            }else{

                toast.error('course adding failed')
            }
        }
    }
    const [file, setfile] = useState(null)
    const [imgPreview, setimgPreview] = useState('')
    const filechangeHandler = (e) => {
        setfile(e.target.files[0])
        setimgPreview(URL.createObjectURL(e.target.files[0]))
    }
    return (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="exampleModalLabel" centered>
            <Modal.Header closeButton>
                <Modal.Title id="exampleModalL abel"><h6>Add Course</h6></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-md text-primary-light mb-16">Thumb Image</h6>
                {/* Upload Image Start */}
                <div className="mb-24 mt-16">
                    <div className="avatar-upload">
                        <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                            <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" hidden onChange={filechangeHandler} />
                            <label
                                htmlFor="imageUpload"
                                className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle"
                            >
                                <Icon icon="solar:camera-outline" className="icon"></Icon>
                            </label>
                        </div>
                        <div className="avatar-preview">
                            <div id="imagePreview">
                                <img src={imgPreview} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Upload Image End */}
                <Form onSubmit={handleSubmit(submitHandler)}>
                    <div className="row">
                        <div className="col-sm-6">
                            <Form.Group controlId="name" className="mb-20">
                                <Form.Label className={"form-label fw-semibold text-primary-light text-sm mb-8"}>
                                    Course Name <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control type="text" {...register('title')} placeholder="Enter course name" className={`radius-8 ${errors?.title ? 'is-invalid' : ''}`} />
                                <div class="invalid-feedback">
                                    {errors?.title?.message}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group controlId="depart" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Category <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Select
                                    {...register('categoryId')}
                                    className={`radius-8 form-select ${errors?.categoryId ? 'is-invalid' : ''}`}
                                >
                                    <option value="">select</option>
                                    {
                                        categoies?.data &&
                                        categoies?.data.map((item) => (
                                            <option value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </Form.Select>
                                <div class="invalid-feedback">
                                    {errors?.categoryId?.message}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group controlId="name" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Price <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control type="text" placeholder="price" className={`radius-8 ${errors?.price ? 'is-invalid' : ''}`} {...register('price')} />
                                <div class="invalid-feedback">
                                    {errors?.price?.message}
                                </div>
                            </Form.Group>

                        </div>
                        <div className="col-sm-6">
                            <Form.Group controlId="Language" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Level <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Select className={`radius-8 form-select${errors?.level ? 'is-invalid' : ''}`} {...register('level')}>
                                    <option value="">select</option>
                                    {
                                        levels?.data &&
                                        levels?.data.map((item) => (
                                            <option value={item.value}>{item.label}</option>
                                        ))
                                    }

                                </Form.Select>
                                <div class="invalid-feedback">
                                    {errors?.level?.message}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-sm-12">
                            <Form.Group controlId="desc" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Requirements
                                </Form.Label>
                                <Form.Control as="textarea" {...register('requirements')} placeholder="Write description..." className={`radius-8 ${errors?.requirements ? 'is-invalid' : ''}`} />
                                <div class="invalid-feedback">
                                    {errors?.requirements?.message}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-sm-12">
                            <Form.Group controlId="desc" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Overview
                                </Form.Label>
                                <Form.Control as="textarea" placeholder="Write description..." {...register('overview')} className={`radius-8 ${errors?.overview ? 'is-invalid' : ''}`} />
                                <div class="invalid-feedback">
                                    {errors?.overview?.message}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-sm-12">
                            <Form.Group controlId="desc" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Description
                                </Form.Label>
                                <Form.Control as="textarea" placeholder="Write description..." {...register('description')}className={`radius-8 ${errors?.description ? 'is-invalid' : ''}`} />
                                <div class="invalid-feedback">
                                    {errors?.description?.message}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <Button variant="outline-danger" onClick={handleClose} className="px-56 py-11 radius-8">
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" className="px-56 py-12 radius-8">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCourseModal