import { useMutation, useQuery } from '@tanstack/react-query'
import { getTiles, getTutorCourseList, getTutorCoursePurchaseList, getTutorStudentStatics, getTutorStudentsList, getTutorTransactionList } from '../service/tutor/tutorService';
import { useAuth } from '../context/AuthContext';

const TutorDashboardApis = {
    GetTiles: () => {
        const response = useQuery({
            queryKey: ['get-tutor-dashboard-tiles'],
            queryFn: () => getTiles()
        })
        return response;
    },
    GetStudentStatics: (period) => {
        const response = useQuery({
            queryKey: ['get-tutor-student-statics', period],
            queryFn: () => getTutorStudentStatics(period)
        })
        return response;
    },
    GetPurchaseList: (start, length = 5, name) => {
        const { userType } = useAuth()
        const response = useQuery({
            queryKey: ['get-tutor-course-purchase-list', start, length, name],
            queryFn: () => getTutorCoursePurchaseList(start, length, name),
            enabled: userType === "tutor"
        })
        return response;
    },
    GetStudentsList: (limit = 5) => {
        const response = useQuery({
            queryKey: ['get-tutor-students-list', limit],
            queryFn: () => getTutorStudentsList(limit)
        })
        return response;
    },
    GetTransactions: (type) => {
        const response = useQuery({
            queryKey: ['get-tutor-transactions', type],
            queryFn: () => getTutorTransactionList(type)
        })
        return response;
    },
    GetCourseList: (limit = 5) => {
        const response = useQuery({
            queryKey: ['get-tutor-course-list', limit],
            queryFn: () => getTutorCourseList(limit)
        })
        return response;
    },

}
export default TutorDashboardApis