import * as yup from "yup"

const CourseSchema = yup
    .object({
        title: yup.string().required(),
        description:yup.string().optional(),
        overview: yup.string().optional(),
        level: yup.string().required(),
        requirements: yup.string().required(),   
        categoryId:yup.string().required(),        
        price: yup.string().matches(/^[0-9]+$/, "Only number allowed").required()
    })
    .required()

export default CourseSchema