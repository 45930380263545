import React from 'react'


function UserInfo({ profileData }) {
    return (
        <div className="col-lg-4">
            <div className="user-grid-card position-relative border radius-16 overflow-hidden bg-base h-100">
                <div className="pb-24 ms-16 mb-24 me-16 mt--10">
                    <div className="text-center border border-top-0 border-start-0 border-end-0">
                        <img
                            src={profileData?.data?.user_detail?.profilePhoto??'/image/user.png'}
                            alt=""
                            className="border br-white border-width-2-px w-200-px h-200-px rounded-circle object-fit-cover"
                        />
                        <h6 className="mb-0 mt-16">{profileData?.data?.user_detail?.firstName + " " + profileData?.data?.user_detail?.lastName??""}</h6>
                        <span className="text-secondary-light mb-16">{profileData?.data?.email}</span>
                    </div>
                    <div className="mt-24">
                        <h6 className="text-xl mb-16">Personal Info</h6>
                        <ul>
                            <li className="d-flex align-items-center gap-1 mb-12">
                                <span className="w-30 text-md fw-semibold text-primary-light">Full Name</span>
                                <span className="w-70 text-secondary-light fw-medium">: {profileData?.data?.user_detail?.firstName + " " + profileData?.data?.user_detail?.lastName??""}</span>
                            </li>
                            <li className="d-flex align-items-center gap-1 mb-12">
                                <span className="w-30 text-md fw-semibold text-primary-light">Email</span>
                                <span className="w-70 text-secondary-light fw-medium">: {profileData?.data?.email}</span>
                            </li>
                            <li className="d-flex align-items-center gap-1 mb-12">
                                <span className="w-30 text-md fw-semibold text-primary-light">Phone Number</span>
                                <span className="w-70 text-secondary-light fw-medium">: {profileData?.data?.user_detail?.mobile??'123456788'}</span>
                            </li>
                            {/* <li className="d-flex align-items-center gap-1 mb-12">
                                <span className="w-30 text-md fw-semibold text-primary-light">Department</span>
                                <span className="w-70 text-secondary-light fw-medium">: Design</span>
                            </li>
                            <li className="d-flex align-items-center gap-1 mb-12">
                                <span className="w-30 text-md fw-semibold text-primary-light">Designation</span>
                                <span className="w-70 text-secondary-light fw-medium">: UI UX Designer</span>
                            </li>
                            <li className="d-flex align-items-center gap-1 mb-12">
                                <span className="w-30 text-md fw-semibold text-primary-light">Languages</span>
                                <span className="w-70 text-secondary-light fw-medium">: English</span>
                            </li> */}
                            <li className="d-flex align-items-center gap-1">
                                <span className="w-30 text-md fw-semibold text-primary-light">Bio</span>
                                <span className="w-70 text-secondary-light fw-medium">
                                    : {profileData?.data?.about??'NA'}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default UserInfo