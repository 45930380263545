import React from 'react'
import ApexCharts from 'react-apexcharts';
import { Icon } from '@iconify/react'
import { formatNumber } from '../../utils/formatNumber';
import Dropdown from 'react-bootstrap/Dropdown';
function Tiles({ tilesData }) {
    const createWidgetChartOptions = (chartColor) => {
        const currentYear = new Date().getFullYear();

        return {

            chart: {
                type: 'area',
                width: 100,
                height: 42,
                sparkline: {
                    enabled: true,
                },
                toolbar: {
                    show: false,
                },
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 2,
                colors: [chartColor],
                lineCap: 'round',
            },
            grid: {
                show: true,
                borderColor: 'transparent',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
                row: {
                    colors: undefined,
                    opacity: 0.5,
                },
                column: {
                    colors: undefined,
                    opacity: 0.5,
                },
                padding: {
                    top: -3,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
            fill: {
                type: 'gradient',
                colors: [chartColor],
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.5,
                    gradientToColors: [`${chartColor}00`],
                    inverseColors: false,
                    opacityFrom: 0.75,
                    opacityTo: 0.3,
                    stops: [0, 100],
                },
            },
            markers: {
                colors: [chartColor],
                strokeWidth: 2,
                size: 0,
                hover: {
                    size: 8,
                },
            },
            xaxis: {
                labels: {
                    show: false,
                },
                categories: [
                    `Jan ${currentYear}`,
                    `Feb ${currentYear}`,
                    `Mar ${currentYear}`,
                    `Apr ${currentYear}`,
                    `May ${currentYear}`,
                    `Jun ${currentYear}`,
                    `Jul ${currentYear}`,
                    `Aug ${currentYear}`,
                    `Sep ${currentYear}`,
                    `Oct ${currentYear}`,
                    `Nov ${currentYear}`,
                    `Dec ${currentYear}`,
                ],
                tooltip: {
                    enabled: false,
                },
            },
            yaxis: {
                labels: {
                    show: false,
                },
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm',
                },
            },
        };
    };
    return (
        <div className="admin_content_row">
            <h6>Sales Report</h6>
            <div className="users_list_section">
                <div className="row gy-4 mb-3">
                    <div className="col-xxl-4 col-sm-6">
                        <div className="card p-3 shadow-none radius-8 border h-100">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                                    <div className="d-flex align-items-center gap-2">
                                        <span className="mb-0 w-48-px h-48-px bg-primary-600 flex-shrink-0 text-white d-flex justify-content-center align-items-center rounded-circle h6 mb-0">
                                            <Icon icon="streamline:money-graph-analytics-business-product-graph-data-chart-analysis" />

                                        </span>
                                        <div>
                                            <span className="mb-2 fw-medium text-secondary-light text-sm">Total Sales</span>
                                            <h6 className="fw-semibold">$ {formatNumber(tilesData?.data?.totalSales)}</h6>
                                        </div>
                                    </div>
                                    {/* <Dropdown className='sales-filter-toggle-btn'>
                                        <Dropdown.Toggle  bsPrefix="custom-toggle" style={{ background: 'none', border: 'none', color: 'black' }}>
                                            <Icon icon="mi:filter" width="24" height="24" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                    {/* <ApexCharts
                                        options={createWidgetChartOptions('#487fff')}
                                        series={[{ data: [35, 45, 38, 41, 36, 43, 37, 55, 40] }]}
                                        type="area"
                                        height={42}
                                        width={100}
                                        className='remove-tooltip-title rounded-tooltip-value'

                                    /> */}
                                </div>
                                <p className="text-sm mb-0">
                                    Total {" "}
                                    <span className="bg-info-focus px-1 rounded-2 fw-medium text-info-main text-sm">
                                        {tilesData?.data?.totalSalesCount ?? 0}
                                    </span> {" "}
                                    sales
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-sm-6">
                        <div className="card p-3 shadow-none radius-8 border h-100">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                                    <div className="d-flex align-items-center gap-2">
                                        <span class="mb-0 w-48-px h-48-px bg-success-main flex-shrink-0 text-white d-flex justify-content-center align-items-center rounded-circle h6">
                                        <Icon icon="quill:snooze-month"/>

                                        </span>
                                        <div>
                                            <span className="mb-2 fw-medium text-secondary-light text-sm">Current Month</span>
                                            <h6 className="fw-semibold">$ {formatNumber(tilesData?.data?.currentMonth)}</h6>
                                        </div>
                                    </div>
                                    {/* <ApexCharts
                                        options={createWidgetChartOptions('#45b369')}
                                        series={[{ data: [35, 45, 38, 41, 36, 43, 37, 55, 40] }]}
                                        type="area"
                                        height={42}
                                        width={100}
                                        className='remove-tooltip-title rounded-tooltip-value'

                                    /> */}
                                </div>
                                <p className="text-sm mb-0">
                                    Current month <span className="bg-success-focus px-1 rounded-2 fw-medium text-success-main text-sm">
                                        {tilesData?.data?.currentMonthSales}</span> sales
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="col-xxl-4 col-sm-6">
                        <div className="card p-3 shadow-none radius-8 border h-100">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
                                    <div className="d-flex align-items-center gap-2">
                                        <span class="mb-0 w-48-px h-48-px bg-yellow text-white flex-shrink-0 d-flex justify-content-center align-items-center rounded-circle h6">
                                        <Icon icon="mdi:page-previous-outline"/>

                                        </span>
                                        <div>
                                            <span className="mb-2 fw-medium text-secondary-light text-sm">Previous Month</span>
                                            <h6 className="fw-semibold">$ {formatNumber(tilesData?.data?.previousMonth)}</h6>
                                        </div>
                                    </div>
                                    {/* <ApexCharts
                                        options={createWidgetChartOptions('#f4941e')}
                                        series={[{ data: [35, 45, 38, 41, 36, 43, 37, 55, 40] }]}
                                        type="area"
                                        height={42}
                                        width={100}
                                        className='remove-tooltip-title rounded-tooltip-value'

                                    /> */}
                                </div>
                                <p className="text-sm mb-0">
                                    <p class="text-sm mb-0">Previous month  <span class="bg-danger-focus px-1 rounded-2 fw-medium text-danger-main text-sm">{tilesData?.data?.previousMonthSales}</span> sales</p>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Tiles