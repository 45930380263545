import React, { useState } from 'react'
import ListRankedTutors from '../../components/tutors/ListRankedTutors'
import TutorsListTable from '../../components/tutors/TutorsListTable'
import AddtutorModal from '../../components/tutors/AddtutorModal';
import DashboardApis from '../../queries/dashboard';
import TutorApis from '../../queries/tutor';
import EditTutorDetails from '../../components/tutors/EditTutorDetails';

function Tutors() {
    const [show, setShow] = useState(false);
    const [page, setpage] = useState(1)
    const [status, setstatus] = useState('')
    const [username, setusername] = useState('')
    const [editUserId, seteditUserId] = useState()
    const [length, setlength] = useState(10)
    const { data: topTutorsData } = DashboardApis.Toptutors()
    const { data: tutorsTabledata, refetch: getAlltutors, isLoading } = TutorApis.GetallTutors(page, length, status, username)
    const [editModal, seteditModal] = useState(false)
    const handleClose = () => setShow(false);
    const closeEditModal = () => seteditModal(false)
    return (
        <>
            <div className="dashboard-main-body">
                <ListRankedTutors topTutorsData={topTutorsData?.data} />
                <TutorsListTable
                    setModalShow={setShow}
                    tutorsTabledata={tutorsTabledata?.data}
                    page={page}
                    setpage={setpage}
                    length={length}
                    status={status}
                    username={username}
                    setstatus={setstatus}
                    setusername={setusername}
                    seteditModal={seteditModal}
                    getAlltutors={getAlltutors}
                    seteditUserId={seteditUserId}
                    IsapiFetching={isLoading}

                />


            </div>
            <AddtutorModal
                show={show}
                handleClose={handleClose}
                getAlltutors={getAlltutors}

            />
            <EditTutorDetails
                show={editModal}
                handleClose={closeEditModal}
                getAlltutors={getAlltutors}
                editUserId={editUserId} />

        </>

    )
}

export default Tutors