import React from 'react'
import { Icon } from '@iconify/react'
import Pagination from '../../common/Pagination'
import TableSkeleton from '../../utils/Skeletons/TableSkeleton'
import noDataimg from '../../assets/images/No data-cuate.png'
import { Link } from 'react-router-dom'
function SalesReportTable({ page, length, setpage, salesReportdata, setusername, isApifetching }) {
    return (
        <div className="card h-100">
            <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
                <div className="d-flex align-items-center flex-wrap gap-3">
                    <form className="navbar-search">
                        <input type="text" className="bg-base h-40-px w-auto" name="search"
                            placeholder="Enter username" onChange={(e) => setusername(e.target.value)} />
                        <Icon icon="ion:search-outline" className="icon" />
                    </form>

                </div>

            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-style mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Students</th>
                                <th scope="col">Invoice</th>
                                {/* <th scope="col">Course</th> */}
                                <th scope="col">Amount</th>
                                <th scope="col">Purchase Date</th>
                                <th scope="col" className="text-center">Status</th>

                            </tr>
                        </thead>
                        {
                            isApifetching ?
                                <TableSkeleton rowCount={6} colCount={6} />
                                :
                                <tbody>
                                    {
                                        salesReportdata?.tabledata?.length > 0 ?
                                            salesReportdata?.tabledata?.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">

                                                                <img src={item.userImage ?? '/image/user.png'} alt="" className="flex-shrink-0 me-12 radius-8" style={{ width: '40px' }} />
                                                                <span className="text-lg text-secondary-light fw-semibold flex-grow-1">{item.username}</span>
                                                            </div>
                                                        </td>
                                                        <td className='invoice_link'><Link to={`/invoice/${item.invoice}`}>{item.invoice}</Link></td>
                                                        {/* <td>{item.course}</td> */}
                                                        <td>${item.amount}</td>
                                                        <td>{item.date}</td>
                                                        <td className="text-center">
                                                            <span className={`${item.status === 'paid' ? 'bg-success-focus' : 'bg-warning-focus'}
                                                        ${item.status === 'paid' ? 'text-success-main' : 'text-secondary-light'} px-24 py-4 rounded-pill fw-medium text-sm`}>{item.status}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr className='text-center'>
                                                <td colSpan={5}>
                                                    <img src={noDataimg} className='w-50' />
                                                </td>
                                            </tr>
                                    }


                                </tbody>
                        }

                    </table>
                </div>

                <Pagination
                    totalEntries={salesReportdata?.totalCount}
                    entriesPerPage={length}
                    currentPage={page}
                    onPageChange={setpage}
                />
            </div>
        </div>
    )
}

export default SalesReportTable