import * as yup from "yup";

const PasswordSchema = yup.object({
    password: yup
        .string()
        .required("Password is a required field"),
    confirmPaswd: yup
        .string()
        .oneOf([yup.ref('password'), null], "Passwords must match")
        .required("Confirm password is a required field")
}).required();

export default PasswordSchema;
