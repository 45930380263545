import React from 'react'

export default function Footer() {
    return (
        <footer className="d-footer">
            <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                    <p className="mb-0">© 2024 LMS. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    )
}
