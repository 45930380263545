import React, { useState } from 'react'
import CourseList from '../../components/courses/CourseList'
import CourseListTable from '../../components/courses/CourseListTable'
import AddCourseModal from '../../components/courses/AddCourseModal';
import CourseApis from '../../queries/course';
import EditCourseModal from '../../components/courses/EditCourseModal';
function Course() {
    const [show, setShow] = useState(false);
    const [page, setpage] = useState(1)
    const [status, setstatus] = useState('')
    const [username, setusername] = useState('')
    const [length, setlength] = useState(10)
    const [editData, seteditData] = useState()
    const [editUserId, seteditUserId] = useState()
    const { data: courseListdata, refetch: getCourseTiles } = CourseApis.GetHighRateCourses()
    const { data: courseTabledata, refetch: getAllcourse, isLoading } = CourseApis.GetCourseTableData(page, length, status, username)
    const { data: categoies } = CourseApis.Getcategories()
    const { data: levels } = CourseApis.GetCourseLevel()
    const [editModal, seteditModal] = useState(false)

    const handleClose = () => setShow(false);
    const closeEditmodal = () => seteditModal(false)
    const editCourseHandler = (item) => {
        seteditData(item)
        seteditModal(true)
    }
    return (
        <>
            <div className="dashboard-main-body">
                <CourseList courseListdata={courseListdata?.data} />

                <CourseListTable
                    setShowModal={setShow}
                    courseTabledata={courseTabledata?.data}
                    page={page}
                    setpage={setpage}
                    length={length}
                    status={status}
                    username={username}
                    setstatus={setstatus}
                    setusername={setusername}
                    seteditModal={editCourseHandler}
                    getAllcourse={getAllcourse}
                    seteditUserId={seteditUserId}
                    IsApiFetching={isLoading}


                />


            </div>
            <AddCourseModal
                show={show}
                handleClose={handleClose}
                categoies={categoies}
                levels={levels}
                getAllcourse={getAllcourse}
                getCourseTiles={getCourseTiles}
            />
            {
                editData &&
                <EditCourseModal
                    show={editModal}
                    editData={editData}
                    handleClose={closeEditmodal}
                    categoies={categoies}
                    levels={levels}
                    getAllcourse={getAllcourse}
                    getCourseTiles={getCourseTiles}
                />
            }

        </>

    )
}

export default Course