import { Icon } from '@iconify/react'
import React from 'react'
import noDataimg from '../../assets/images/No data-cuate.png'
import avatarImg from '../../assets/images/users/user3.png'
import { Link } from 'react-router-dom'

export default function Courses({ CourseData }) {

    return (
        <div className="col-xxl-6 col-lg-6">
            <div className="card h-100">
                <div className="card-body">
                    <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-20">
                        <h6 className="mb-2 fw-bold text-lg mb-0">Courses</h6>
                        <Link to="/course" className="text-primary-600 hover-text-primary d-flex align-items-center gap-1">
                            View All
                            <Icon icon="solar:alt-arrow-right-linear" className="icon"></Icon>
                        </Link>
                    </div>

                    <div className="mt-32">
                        {CourseData?.tabledata?.length > 0 ?
                            CourseData?.tabledata?.map((item, index) => (
                                <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
                                    <div className="d-flex align-items-center gap-2">
                                        <img src={item.image ?? '/image/user.png'} alt="" className="w-40-px h-40-px radius-8 flex-shrink-0" />
                                        <div className="flex-grow-1">
                                            <h6 className="text-md mb-0 fw-normal">{item.title}</h6>
                                            <span className="text-sm text-secondary-light fw-normal">{item.course}</span>
                                        </div>
                                    </div>
                                    <span className="text-primary-light text-md fw-medium">
                                        {
                                            item.rating>0?

                                            Array(5).fill('').map(()=>{
                                                return(
                                                    <Icon icon="mage:star-fill" width="24" height="24" className='star-icon' />
                                                )
                                            })
                                            : item.rating
                                        }
                                        
                                        {/* {item.rating} */}
                                    </span>
                                </div>
                            ))
                            :
                            <center>
                                <img src={noDataimg} className='w-75' />
                            </center>

                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
