import React, { useEffect } from 'react'
import logo from '../assets/images/logo.png'
import errorImg from '../assets/images/error-img.png'
import { useRouteError, useNavigate } from 'react-router-dom'
import Aos from 'aos'
import { useAuth } from '../context/AuthContext'
function ErrorBoundary() {
    const error = useRouteError();
    useEffect(() => {
        Aos.init()
    }, [])
    const navigate = useNavigate()
    
    const { isAuthenticated } = useAuth()
    const backTohomeHandler = () => {
        const path = isAuthenticated ? '/dashboard' : '/login'
        navigate(path)
    }
    return (
        <section className="error_box_sec">

            <div className="error_box">
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='error-info-section'>
                            <h1 data-aos="zoom-in" >Oops!</h1>
                            <span><p>Something went wrong !!</p></span>
                            <br />
                            <button onClick={backTohomeHandler}>Back To Dashboard</button>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <img src={errorImg} data-aos="fade-down" />
                    </div>
                </div>



            </div>

        </section>
    )
}

export default ErrorBoundary