import React from 'react'
import { Icon } from '@iconify/react'
import courseImg from '../../assets/images/online-course.png'
function CourseList({ courseListdata }) {
    return (
        <div className="admin_content_row">
            <h6>Course Preview</h6>
            <div className="users_list_section">
                <div className="users_list_row">
                    {
                        courseListdata?.tabledata &&
                        courseListdata?.tabledata.map((item, index) => {
                            return (
                                <div className="users_list_box">
                                    <div className="users_list_box_img">
                                        <img src={item.image??courseImg} />
                                    </div>
                                    <div className="users_list_box_cnt">
                                        <h6>{item.title}</h6>
                                        <h6>
                                            Rating: <Icon icon="material-symbols:star" className='star-icon'></Icon> {item.rating}
                                        </h6>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>

    )
}

export default CourseList